<template>
  <div class="form-info-user" v-loading="loading">
    <div class="form-info-user__header">
      <img class="form-info-user__header-icon" src="@/assets/img/user/icon-info.svg" alt="info">
      <div class="form-info-user__header-title">Информация</div>
    </div>
    <form-input class="form-info-user__input"
                v-model="userInfo.fullName"
                label="Фамилия Имя"
                :validator="$v.userInfo.fullName"
                placeholder="Фамилия Имя"/>

    <div class="form-info-user__radio-group">
      <label class="form-info-user__title-select" for="gender-select">Пол</label>
      <div class="form-info-user__radio-group-input">
        <el-radio v-model="userInfo.sex" label="male">Мужчина</el-radio>
        <el-radio v-model="userInfo.sex" label="female">Женщина</el-radio>
      </div>
    </div>


    <div class="form-info-user__date-picker">
      <label class="form-info-user__title-select" for="date-picker">Дата рождения</label>
      <el-date-picker
          popper-class="user-date-birthday"
          id="date-picker"
          type="date"
          v-model="userInfo.birthday"
          placeholder="Выберите дату рождения"
          :picker-options="pickerOptions">
      </el-date-picker>
    </div>

    <form-input class="form-info-user__input"
                v-model="userInfo.phoneNumber"
                type="text"
                label="Номер телефона"
                :validator="$v.userInfo.phoneNumber"
                :mask="'+7 (###) ### ## ##'"
                placeholder="+7 (904) --- -- --"/>

    <form-input class="form-info-user__input"
                v-model="userInfo.location"
                label="Место проживания"
                placeholder="Введите место проживания"/>

    <div class="form-info-user__btn-save" @click="updateInfoUser">Сохранить изменения</div>

  </div>
</template>

<script>
import FormInput from "../../components/FormInput";

import {userService} from "../../user.service";

import {validationMixin} from "vuelidate";
import {required, maxLength, minLength} from 'vuelidate/lib/validators'
import {validate} from "../../../../utils/validators";

export default {
  name: "FormInfoInput",
  mixins: [validationMixin],
  components: {
    FormInput
  },
  data() {
    return {
      loading: false,
      userInfo: {
        fullName: '',
        sex: '',
        birthday: '',
        phoneNumber: '',
        location: '',
      },
      options: [{
        value: 'male',
        label: 'Мужчина'
      },
        {
          value: 'female',
          label: 'Женщина'
        }],
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now()
        }
      },
    }
  },
  mounted() {
    userService.getUserInfo().then((data) => {
      this.userInfo.fullName = data.fullName
      this.userInfo.birthday = data.birthday
      this.userInfo.sex = data.sex
      this.userInfo.location = data.location
      this.userInfo.phoneNumber = data.phoneNumber
    })
  },
  methods: {
    updateInfoUser() {

      if (validate(this.$v)) {
        if (this.userInfo.birthday) {
          const dateBirthday = new Date(this.userInfo.birthday.toString())
          this.userInfo.birthday = `${dateBirthday.getFullYear()}-${dateBirthday.getMonth() + 1}-${dateBirthday.getDate()}`
        }
        this.loading = true
        userService.patchUserInfo(this.userInfo)
            .finally(() => this.loading = false)
      }
    }
  },
  validations: {
    userInfo: {
      fullName: {required, maxLength: maxLength(40)},
      phoneNumber: {required, phoneRu: minLength(18)}
    },
  }
}
</script>

<style lang="scss">
.form-info-user {
  padding-right: 45px;
  @media screen and (max-width: 768px) {
    padding-right: 16px;
  }

  &__header {
    display: flex;
    align-items: center;
    margin-bottom: 24px;

    &-icon {
      margin-right: 16px;
    }

    &-title {
      font-family: Raleway;
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: 32px;
      letter-spacing: 0em;
      text-align: left;
    }
  }

  &__input {
    margin-bottom: 16px;
  }

  &__radio-group {
    display: flex;
    flex-direction: column;

    margin-bottom: 16px;
  }

  &__radio-group-input {
    display: flex;
    align-items: center;

    width: 100%;
    height: 48px;

    padding-left: 14px;

    background: #FFFFFF;
    border: 0.5px solid #C4C4C4;
    box-sizing: border-box;
    border-radius: 4px;

    .el-radio {
      display: flex;
      flex-direction: row-reverse;

      &__inner {
        width: 24px;
        height: 24px;
        background: #FFFFFF;

        margin-left: 14px;
      }

      &__label {
        font-family: Montserrat, sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0em;
        text-align: left;

        padding-left: 0px;

        color: #2A2A2A;
      }
    }

    .el-radio.is-checked {
      .el-radio {
        &__label {
          color: #2A2A2A;
        }

        &__inner {
          background: #FFFFFF;

          &:after {
            width: 14px;
            height: 14px;
            background: #1454F2;
          }
        }
      }
    }
  }

  &__title-select {
    font-family: Montserrat, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;

    color: #000000;
  }

  .el-date-editor {
    width: 100%;
    margin-bottom: 16px;

    .el-input__inner {
      height: 48px;
      font-family: Montserrat, sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0em;
      text-align: left;

      border: 0.5px solid #C4C4C4;
      box-sizing: border-box;
      border-radius: 4px;
      transition: 0.2s;

      &:focus {
        border-color: #7B8DFF;
      }

      &::placeholder {
        font-family: Montserrat, sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0em;
        text-align: left;

      }
    }
  }

  &__btn-save {
    display: flex;
    justify-content: center;
    align-items: center;

    height: 52px;
    width: 248px;

    margin-top: 30px;

    font-family: Montserrat, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;

    color: #FFFFFF;

    background: #1454F2;
    border-radius: 100px;

    cursor: pointer;

    &:hover {
      background-color: #4072ef;
    }
  }

}

.user-gender-info-select {

  width: 200px !important;
  min-width: 150px !important;

  .el-select-dropdown__item {
    font-family: Montserrat, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
  }
}

.user-date-birthday {
  .el-date-picker__header {
    font-family: Montserrat, sans-serif;
  }

  .el-picker-panel__content {
    font-family: Montserrat, sans-serif;
  }
}
</style>
