<template>
  <div class="form-update-photo">
    <div class="form-update-photo__photo"
         :style="{'border': (!avatar || avatar === 'null')?'1px solid #1454F2':'none'}">
      <div v-if="!avatar || avatar === 'null'"
           class="form-update-photo__empty"
           @click="$refs.input.click()">
        <img class="form-update-photo__default-avatar" src="@/assets/img/user/add-icon.svg" alt="photo">
        <div class="form-update-photo__title">Добавьте фото</div>
      </div>
      <img v-else class="form-update-photo__user-photo" :src=" avatar" alt="user">
    </div>

    <div class="form-update-photo__action">
      <div class="form-update-photo__update" @click="$refs.input.click()">Изменить фото</div>
      <div v-if="avatar" class="form-update-photo__delete" @click="deleteAvatar">Удалить фото</div>
    </div>

    <input class="form-update-photo__input-photo"
           id="photo-add"
           type="file"
           ref="input"
           accept="image/jpeg,image/png"
           @change="handleFile">
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import {userService} from "../../user.service";

import store from '@/store'

export default {
  name: "FormLoadPhoto",
  data() {
    return {
      avatar: null
    }
  },
  computed: {
    ...mapGetters(['userAvatar'])
  },
  mounted() {
    userService.getAvatar().then((data) => {
      this.avatar = data
    })
  },
  methods: {
    handleFile() {

      if (this.userAvatar && this.isValidFile(this.$refs.input.files[0].type)) {
        userService.updateAvatar(this.$refs.input.files[0]).then(() => {
          this.avatar = window.URL.createObjectURL(this.$refs.input.files[0])
          store.commit('setUserAvatar', this.avatar)
        })
      }
      else if (this.isValidFile(this.$refs.input.files[0].type)) {
        userService.addAvatar(this.$refs.input.files[0]).then(() => {
          this.avatar = window.URL.createObjectURL(this.$refs.input.files[0])
          store.commit('setUserAvatar', this.avatar)
        })

      }

    },
    deleteAvatar() {
      this.$refs.input.value = ""
      this.avatar = null
      userService.deleteAvatar()
    },
    isValidFile(type) {
      if (type !== 'image/png' && type !== 'image/jpeg') {
        this.$notify({
          title: 'Предупреждение',
          message: 'Тип файла не соответствует jpeg или png',
          type: 'warning'
        });
        return false
      } else
        return true
    }
  }
}
</script>

<style lang="scss" scoped>
.form-update-photo {
  display: flex;

  margin-top: 24px;
  margin-bottom: 56px;

  &__photo {
    display: flex;
    justify-content: center;
    align-items: center;

    width: 100px;
    height: 100px;

    box-sizing: border-box;
    border-radius: 100%;
  }

  &__empty {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    cursor: pointer;
  }

  &__title {
    font-family: Montserrat;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 14px;
    width: 80px;
    letter-spacing: 0em;
    text-align: center;

    margin-top: 4px;

  }

  &__action {
    display: flex;
    flex-direction: column;

    margin-left: 32px;
    margin-top: 25px;
  }

  &__update {
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;

    color: #121212;
    cursor: pointer;
  }

  &__delete {
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;

    color: #E82A2A;
    cursor: pointer;
  }

  &__input-photo {
    display: none;
  }

  &__user-photo {
    width: 100%;
    height: 100%;

    object-fit: cover;

    border-radius: 100%;
  }
}
</style>
