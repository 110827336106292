<template>
<div class="form-information">
  <div class="form-information__item">
    <form-info-input/>
  </div>
  <div class="form-information__item">
    <form-password-input/>
  </div>
</div>
</template>

<script>
import FormInfoInput from "./FormInfoInput";
import FormPasswordInput from "./FormPasswordInput";

export default {
  name: "FormInformation",
  components:{
    FormInfoInput,
    FormPasswordInput
  }
}
</script>

<style lang="scss" scoped>
.form-information{
  display: flex;

  @media screen and (max-width: 768px) {
    flex-wrap: wrap;

    &__item {
      &:last-child {
        margin-top: 30px;
      }
    }

  }

  &__item {
    width: 100%;
  }
}
</style>
