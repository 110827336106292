<template>
  <div class="form-password-user">
    <div class="form-password-user__header">
      <img class="form-password-user__header-icon" src="@/assets/img/user/icon-password.svg" alt="info">
      <div class="form-password-user__header-title">Пароль</div>
    </div>
    <div class="form-password-user__password">
      <form-input v-model="password"
                  type="password"
                  label="Пароль"
                  :validator="$v.password"
                  placeholder="Текущий пароль"/>
      <div class="form-password-user__forget-text" @click="$refs.emailModalWindow.openModal()">Забыли пароль?</div>
    </div>
    <div class="form-password-user__new-password">
      <form-input v-model="newPassword"
                  :validator="$v.newPassword"
                  type="password"
                  placeholder="Введите свой новый пароль"/>

      <form-input v-model="repeatNewPassword"
                  type="password"
                  :validator="$v.repeatNewPassword"
                  placeholder="Подтвердите свой новый пароль"/>
    </div>
    <div class="form-password-user__btn-save" @click="updatePassword">Сохранить пароль</div>
    <email-modal ref="emailModalWindow"/>
  </div>
</template>

<script>
import FormInput from "../../components/FormInput";
import EmailModal from "../../auth/password-recovery/modal/EmailModal";

import {userService} from "../../user.service";
import {validationMixin} from "vuelidate";
import {required, minLength, maxLength, sameAs} from 'vuelidate/lib/validators'
import {validate} from "../../../../utils/validators";

export default {
  name: "FormPasswordInput",
  mixins: [validationMixin],
  components: {
    FormInput,
    EmailModal
  },
  data() {
    return {
      password: '',
      newPassword: '',
      repeatNewPassword: '',
    }
  },
  methods: {
    updatePassword() {
      if (validate(this.$v)) {
        userService.updatePassword(this.password, this.newPassword)

        this.$v.$reset()
      }
    }
  },
  validations: {
    password: {
      required,
      minLength: minLength(6),
      maxLength: maxLength(64),
    },
    newPassword: {
      required,
      minLength: minLength(6),
      maxLength: maxLength(64),
    },
    repeatNewPassword: {
      required,
      sameAs: sameAs('newPassword')
    },
  }
}
</script>

<style lang="scss">
.form-password-user {
  padding-left: 15px;
  padding-right: 30px;

  @media screen and (max-width: 768px) {
    padding-left: 0;
    //padding-bottom: 30px;
    padding-right: 16px;
  }

  &__header {
    display: flex;
    align-items: center;
    margin-bottom: 24px;

    &-icon {
      margin-right: 16px;
    }

    &-title {
      font-family: Raleway;
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: 32px;
      letter-spacing: 0em;
      text-align: left;
    }
  }

  &__password {

  }

  &__new-password {
    margin-top: 183px;
    @media screen and (max-width: 768px) {
      margin-top: 14px;
    }

    .form-input:first-child {
      margin-bottom: 40px;
    }
  }

  &__forget-text {
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;

    margin-top: 10px;
    cursor: pointer;
  }

  &__btn-save {
    display: flex;
    justify-content: center;
    align-items: center;

    height: 52px;
    width: 248px;

    margin-top: 30px;

    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;

    color: #FFFFFF;

    background: #1454F2;
    border-radius: 100px;

    cursor: pointer;

    &:hover {
      background-color: #4072ef;
    }
  }

  .form-input__eye {
    position: absolute;
    right: 13px;
    bottom: 15px;
    cursor: pointer;
  }
}

</style>
