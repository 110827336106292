<template>
  <div class="settings-wrapper">
    <div class="settings-wrapper__header">
      <div class="settings-wrapper__title">Настройки</div>
      <img src="@/assets/img/user/icon-settings.svg" alt="settings">
    </div>
    <form-load-photo/>
    <form-information/>

    <img class="settings-wrapper__animation-picture" src="@/assets/img/user/animation/element-ui.svg">
  </div>
</template>

<script>
import FormLoadPhoto from "./components/FormLoadPhoto";
import FormInformation from "./components/FormInformation";

export default {
  name: "Settings",
  components: {
    FormLoadPhoto,
    FormInformation
  }
}
</script>

<style lang="scss" scoped>
.settings-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;

  margin-top: 20px;

  box-sizing: border-box;
  padding-bottom: 30px;

  transition: 0.2s;
  @media screen and (max-width: 996px) {
    padding-left: 16px;
  }

  &__header {
    display: flex;
    align-items: center;
  }

  &__title {
    font-family: Raleway;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 40px;
    letter-spacing: 0em;
    text-align: left;
    color: #121212;

    margin-right: 72px;
  }

  &__animation-picture {
    position: absolute;
    top: 150px;
    right: 0px;
    transition: 0.2s;
    z-index: -1;
  }
}

@media screen and (max-width: 1660px) {

  .settings-wrapper__animation-picture {
    width: 200px;
    top: 200px;
    transition: 0.2s;
  }
}

@media screen and (max-width: 1550px) {

  .settings-wrapper__animation-picture {
    width: 180px;
    top: 220px;
    transition: 0.2s;
  }
}

@media screen and (max-width: 1450px) {

  .settings-wrapper__animation-picture {
    width: 160px;
    top: 240px;
    transition: 0.2s;
  }
}

@media screen and (max-width: 980px) {

  .settings-wrapper__animation-picture {
    display: none;
  }
}

</style>
